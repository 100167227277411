import Vue from 'vue';
import { ActionContext, ActionTree, MutationTree } from 'vuex';

const STATE = () => ({
	need_fetch: false,
	need_refresh: false
});

const MUTATIONS: MutationTree<StateCommon> = {
	NEED_FETCH(state, data?: string)
	{
		state.need_fetch = true;
	},
	NEED_FETCH_DONE(state)
	{
		state.need_fetch = false;
	},
	NEED_REFRESH(state)
	{
		state.need_refresh = true;
	},
	NEED_REFRESH_DONE(state)
	{
		state.need_refresh = false;
	}
};

export default
{
	namespaced: true,
	state: STATE,
	mutations: MUTATIONS
}