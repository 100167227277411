import Vue from 'vue';
import PluginRouter from '@/plugins/router';
import * as Sentry from '@sentry/vue';
import * as SentryTracing from '@sentry/tracing';

if(process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_DSN)
{
	const Package = require('../../package.json');
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN as string,
		release: Package.name + '@' + Package.version,
		environment: process.env.VUE_APP_SENTRY_ENVIRONMENT as string,
		trackComponents: true,
		tracesSampleRate: 0.1,
		integrations: [
			new SentryTracing.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(PluginRouter)
			})
		],
		ignoreErrors: [
			'ChunkLoadError',
			'RequestTimeTooSkewed'
		]
	});
}