import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 회사소개: 이음이란
	 */
	{
		name: 'company',
		path: '/company',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "company" */ '@/views/company/Index.vue')
	},

	/**
	 * @brief 회사소개: 브랜드 > 채들소개
	 */
	{
		name: 'company-brand',
		path: '/company/brand',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "company-brand" */ '@/views/company/Brand.vue')
	},

	/**
	 * @brief 회사소개: 브랜드 > BI/CI
	 */
	{
		name: 'company-logo',
		path: '/company/logo',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "company-logo" */ '@/views/company/Logo.vue')
	},

	/**
	 * @brief 회사소개: 브랜드 > 다운로드
	 */
	{
		name: 'company-download',
		path: '/company/download',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "company-download" */ '@/views/company/Download.vue')
	},

	/**
	 * @brief 회사소개: 인재채용
	 */
	{
		name: 'company-recruit',
		path: '/company/recruit',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "company-recruit" */ '@/views/company/Recruit.vue')
	}
];

export default routes;