import Vue from 'vue';
import LayoutDefault from '@/layouts/Default.vue';
import LayoutEmpty from '@/layouts/Empty.vue';

export default Vue.extend({
	computed:
	{
		layout:
		{
			get(): string
			{
				const layout = this.$route.meta && this.$route.meta.layout as string;
				return 'layout-' + (layout || 'empty');
			}
		}
	},
	components:
	{
		LayoutDefault,
		LayoutEmpty
	}
});