import Vue from 'vue';

const CommonModule = (constructor: Vue) => ({
	emit: (event: string) => {
		constructor.$emit(event);
	},
	on: (event: string, callback: Function) => {
		constructor.$on(event, callback);
	},
	off: (event: string, callback: Function) => {
		constructor.$off(event, callback);
	}
});

const Plugin = {
	install(_Vue: typeof Vue)
	{
		const constructor = new Vue();
		Vue.prototype.$eventBus = CommonModule(constructor);
	}
};

Vue.use(Plugin);