import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 문의하기: 고객센터
	 */
	{
		name: 'contact-customer',
		path: '/contact/customer',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-customer" */ '@/views/contact/Customer.vue')
	},

	/**
	 * @brief 문의하기: 자주 묻는 질문
	 */
	{
		name: 'contact-faq',
		path: '/contact/faq',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-faq" */ '@/views/contact/Frequently.vue')
	},

	/**
	 * @brief 문의하기: 문의하기
	 */
	{
		name: 'contact-qna',
		path: '/contact/qna',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-question" */ '@/views/contact/Question.vue')
	},

	/**
	 * @brief 문의하기: 아이디어 제안하기
	 */
	{
		name: 'contact-idea',
		path: '/contact/idea',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-idea" */ '@/views/contact/Idea.vue')
	},

	/**
	 * @brief 문의하기: 제안서 신청
	 */
	{
		name: 'contact-proposal',
		path: '/contact/proposal',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-proposal" */ '@/views/contact/Proposal.vue')
	},

	/**
	 * @brief 문의하기: 사업제휴
	 */
	{
		name: 'contact-alliance',
		path: '/contact/alliance',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "contact-alliance" */ '@/views/contact/Alliance.vue')
	}
];

export default routes;