import Vue from 'vue';
import * as VeeValidate from 'vee-validate';
import * as VeeValidateRules from 'vee-validate/dist/rules';

Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
Vue.component('ValidationProvider', VeeValidate.ValidationProvider);

for(const [rule, validation] of Object.entries(VeeValidateRules))
{
	VeeValidate.extend(rule, {
		...validation
	});
}

VeeValidate.extend('cellphone', {
	validate: (value: any) => {
		if(typeof(value) === 'string')
		{
			return /^(01[016789])(\d{3,4})(\d{4})$/gi.test(value);
		}

		return !value.reduce((acc: boolean[], val: string) => [...acc, /^(01[016789])(\d{3,4})(\d{4})$/gi.test(val)], []).includes(false);
	}
});