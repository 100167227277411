import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 서비스: 왜 채들일까
	 */
	{
		name: 'service',
		path: '/service',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service" */ '@/views/service/Index.vue')
	},

	/**
	 * @brief 서비스: 채들 > 채들의 장점
	 */
	{
		name: 'service-inhabitant-advantage',
		path: '/service/inhabitant/advantage',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-inhabitant-advantage" */ '@/views/service/InhabitantAdvantage.vue')
	},
	{
		name: 'service-inhabitant-advantage1',
		path: '/service/inhabitant/advantage1',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-inhabitant-advantage" */ '@/views/service/InhabitantAdvantage.vue')
	},

	/**
	 * @brief 서비스: 채들 > 기능 상세보기
	 */
	{
		name: 'service-inhabitant-detail',
		path: '/service/inhabitant/detail',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-inhabitant-detail" */ '@/views/service/InhabitantDetail.vue')
	},

	/**
	 * @brief 서비스: 채들어드민 > 채들어드민의 장점
	 */
	{
		name: 'service-admin-advantage',
		path: '/service/admin/advantage',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-admin-advantage" */ '@/views/service/AdminAdvantage.vue')
	},
	{
		name: 'service-admin-advantage1',
		path: '/service/admin/advantage1',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-admin-advantage" */ '@/views/service/AdminAdvantage.vue')
	},

	/**
	 * @brief 서비스: 채들어드민 > 기능 상세보기
	 */
	{
		name: 'service-admin-detail',
		path: '/service/admin/detail',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-admin-detail" */ '@/views/service/AdminDetail.vue')
	},
	/**
	 * @brief 서비스: 채들크루 > 채들크루의 장점
	 */
	{
		name: 'service-crew-advantage',
		path: '/service/crew/advantage',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-crew-advantage" */ '@/views/service/CrewAdvantage.vue')
	},
	{
		name: 'service-crew-advantage1',
		path: '/service/crew/advantage1',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-crew-advantage" */ '@/views/service/CrewAdvantage.vue')
	},

	/**
	 * @brief 서비스: 채들크루 > 기능 상세보기
	 */
	{
		name: 'service-crew-detail',
		path: '/service/crew/detail',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "service-crew-detail" */ '@/views/service/CrewDetail.vue')
	}
];

export default routes;