import Vue from 'vue';
import Vuetify from 'vuetify';
import VuetifyLocaleKorean from 'vuetify/lib/locale/ko';

Vuetify.config.silent = process.env.NODE_ENV === 'production';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		current: String(process.env.VUE_APP_LOCALE || 'ko'),
		locales: {
			ko: VuetifyLocaleKorean
		}
	},
	theme: {
		dark: true,
	},
});