import Vue from 'vue';

export default Vue.extend({
    data () {
        return {
            dialog: false,
            drawer: false,
            group: null,
            tabs_app: false,
        }
    },
    watch: {
        group () {
            this.drawer = false
        },
    },
});
