import Vue from 'vue';
import AWS from 'aws-sdk';

AWS.config.region = process.env.VUE_APP_AWS_REGION as string;
AWS.config.credentials = new AWS.Credentials({
	accessKeyId: process.env.VUE_APP_AWS_CREDENTIAL_ACCESS_KEY_ID,
	secretAccessKey: process.env.VUE_APP_AWS_CREDENTIAL_SECRET_ACCESS_KEY
}),

AWS.config.update({
	httpOptions: {
		timeout: 10000
	}
});

const Lambda = new AWS.Lambda();
const LambdaModule = {
	invoke: async (name: string, type: string, payload: string) => {
		const params = {
			FunctionName: name,
			InvocationType: type,
			Payload: payload
		};

		return Lambda.invoke(params).promise();
	}
};

const Plugin = {
	install(_Vue: typeof Vue)
	{
		_Vue.prototype.$aws = {
			lambda: LambdaModule
		};
	}
};

Vue.use(Plugin);
