import Vue from 'vue';
import App from '@/App.vue';
import PluginRouter from '@/plugins/router';
import PluginVuetify from '@/plugins/vuetify';
import PluginVuex from '@/plugins/vuex';
import '@/styles/index.scss';
import '@/filters';
import '@/helpers';
import '@/plugins';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.config.silent = process.env.NODE_ENV === 'production';

const constructor = new Vue({
	router: PluginRouter,
	store: PluginVuex,
	vuetify: PluginVuetify,
	render: (h: any) => h(App)
});

constructor.$mount('#app');