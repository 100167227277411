import Vue from 'vue';
import { ActionContext, ActionTree, MutationTree } from 'vuex';

const ACTIONS: ActionTree<any, any> = {

    // 개인정보 활용 동의 처리
    async agreement_create({ state, commit, dispatch }: ActionContext<any, any>, data: any)
    {
        return Vue.prototype.$axios.put(`/inhabitant/v1/agreement/${data.agreement_idx}`, {})
    },

    // 개인정보 활용 동의 상세정보
    async agreement_detail({ state, commit, dispatch }: ActionContext<any, any>, data: any)
    {
        return Vue.prototype.$axios.get(`/inhabitant/v1/agreement/${data.agreement_idx}`, {})
    },

    // 개인정보 활용 동의 약관내용 상세정보
    async agreement_terms_detail({ state, commit, dispatch }: ActionContext<any, any>, data: any)
    {
        return Vue.prototype.$axios.get(`/inhabitant/v1/agreement/${data.agreement_idx}/terms`, {})
    }
}

export default
{
    namespaced: true,
    actions: ACTIONS
}
