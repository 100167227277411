import Vue from 'vue';
import Axios from 'axios';

Axios.interceptors.request.use(
	(config) => {
		if(config.headers)
		{
			config.headers['Cache-Control'] = 'no-store, must-revalidate, post-check=0, pre-check=0, max-age=0';
			config.headers['Expires'] = '0';
			config.headers['Pragma'] = 'no-cache';
		}

		return config;
	}
);

Axios.interceptors.response.use(
	(response) => response,
	(error) => {
		if(error && error.message === 'Network Error')
		{
			return Promise.reject({
				status: 503,
				error: 'NETWORK_ERROR_HAS_OCCURRED'
			});
		}

		return Promise.reject({
			status: error.response.status,
			error: error.response.data.error
		});
	}
);

const CommonModule = {
	delete: (path: string, params: Record<string, string> | null, data: object | null) => {
		return Axios({
			method: 'DELETE',
			url: HelperModule.get_url(path, params),
			data: data,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},
	get: (path: string, params: Record<string, string> | null) => {
		return Axios({
			method: 'GET',
			url: HelperModule.get_url(path, params),
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},
	post: (path: string, params: Record<string, string> | null, data: object | null) => {
		return Axios({
			method: 'POST',
			url: HelperModule.get_url(path, params),
			data: data,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},
	put: (path: string, params: Record<string, string> | null, data: object | null) => {
		return Axios({
			method: 'PUT',
			url: HelperModule.get_url(path, params),
			data: data,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	}
};

const HelperModule = {
	get_query: (params?: Record<string, string> | null) => {
		if(params)
		{
			const initialize: any = [];
			for(const [ key, values ] of Object.entries(params))
			{
				if(!values)
				{
					continue;
				}

				if(!Array.isArray(values))
				{
					initialize.push([ key, values ]);
					continue;
				}

				for(const value of values)
				{
					initialize.push([ key, value ]);
				}
			}

			const result = new URLSearchParams(initialize).toString();
			if(result)
			{
				return '?' + result;
			}
		}

		return '';
	},
	get_response: (request: any, promise: boolean, success?: any, failure?: any) => {
		if(!promise)
		{
			return (
				request
				.then((response: any) => HelperModule.on_success(response, success))
				.catch((e: any) => HelperModule.on_failure(e, failure))
			);
		}

		return request;
	},
	get_url: (path: string, params?: Record<string, string> | null) => {
		const result = process.env.VUE_APP_API_ENDPOINT + path.replace(/^(.+)(\/)$/gi, '$1');
		return result + HelperModule.get_query(params);
	},
	on_failure: (error: any, failure: any) => {
		if(failure)
		{
			return failure(error);
		}

		switch(error.status)
		{
			case 401 : {
				window.location.replace('/sign-in');
				return false;
			}

			default : {
				Vue.prototype.$toasted.show(error.error);
				return false;
			}
		}
	},
	on_success: (response: object, success: any) => {
		if(!success)
		{
			return response;
		}

		return success(response);
	}
};

const Plugin = {
	install(_Vue: typeof Vue)
	{
		_Vue.prototype.$axios = {
			delete: (path: string, params: Record<string, string> | null = null, data: object | null = null, promise = true, success?: any, failure?: any) => {
				const request = CommonModule.delete(path, params, data);
				return HelperModule.get_response(request, promise, success, failure);
			},
			get: (path: string, params: Record<string, string> | null = null, promise = true, success?: any, failure?: any) => {
				const request = CommonModule.get(path, params);
				return HelperModule.get_response(request, promise, success, failure);
			},
			post: (path: string, params: Record<string, string> | null = null, data: object | null = null, promise = true, success?: any, failure?: any) => {
				const request = CommonModule.post(path, params, data);
				return HelperModule.get_response(request, promise, success, failure);
			},
			put: (path: string, params: Record<string, string> | null = null, data: object | null = null, promise = true, success?: any, failure?: any) => {
				const request = CommonModule.put(path, params, data);
				return HelperModule.get_response(request, promise, success, failure);
			}
		};
	}
};

Vue.use(Plugin);