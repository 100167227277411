import Vue from 'vue';
import VueRouter from 'vue-router';
import PluginVuex from '@/plugins/vuex';
import Routes from '@/routes';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: Routes,
	scrollBehavior(to, from, savedPosition)
	{
		if(savedPosition)
		{
			return savedPosition;
		}

		if(to.hash)
		{
			return {
				selector: to.hash,
				behavior: 'smooth',
				offset: {
					x: 0,
					y: 64
				}
			}
		}

		return {
			x: 0,
			y: 0
		};
	}
});

router.onError((e: Error) => {
	if(e.name === 'ChunkLoadError')
	{
		PluginVuex.commit('common/SET_DIALOG', {
			status: 'WARNING',
			content: '시스템 파일이 변경되어 페이지 새로고침을 진행합니다.<br />이 후에도 같은 메세지가 나타난다면 강제 새로고침을 해주세요.',
			confirm: async () => {
				window.location.reload();
			}
		});
	}
});

export default router;