import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    /**
     * @brief 서비스: 알림톡 > 개인정보 동의
     */
    {
        name: 'userAgreement',
        path: '/private/consent/:constructionIdx',
        meta: { layout: 'empty' },
        component: () => import (/* webpackChunkName: "userAgreement" */ '@/views/kakaoTalk/consent.vue')
    },
    /**
     * @brief 서비스: 알림톡 > 개인정보 동의 > 완료
     */
    {
        name: 'userAgreementComplete',
        path: '/private/consentComplete',
        meta: { layout: 'empty' },
        component: () => import (/* webpackChunkName: "userAgreement" */ '@/views/kakaoTalk/consentComplete.vue')
    },
    /**
     * @brief 서비스: 알림톡 > 개인정보 동의 > 잘못접근
     */
    {
        name: 'userAgreementError',
        path: '/private/consentError',
        meta: { layout: 'empty' },
        component: () => import (/* webpackChunkName: "userAgreement" */ '@/views/kakaoTalk/consentError.vue')
    },
    /**
     * @brief 서비스: 알림톡 > 개인정보 동의 > 이미 동의 완료
     */
    {
        name: 'userAgreementEnd',
        path: '/private/consentEnd',
        meta: { layout: 'empty' },
        component: () => import (/* webpackChunkName: "userAgreement" */ '@/views/kakaoTalk/consentEnd.vue')
    },
    /**
     * @brief 서비스: 알림톡 > 개인정보 동의 페이지
     */
    {
        name: 'userAgreementComplete',
        path: '/private/terms/:constructionIdx',
        meta: { layout: 'empty' },
        component: () => import (/* webpackChunkName: "userAgreement" */ '@/views/kakaoTalk/terms.vue')
    }
];

export default routes;
