import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 채들연구소
	 */
	{
		name: 'laboratory',
		path: '/laboratory',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/laboratory/Index.vue')
	}
];

export default routes;