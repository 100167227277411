import Vue from 'vue';
import { vsprintf } from 'sprintf-js';
import CryptoJS from 'crypto-js';

const CommonModule = {
	get_params_array: (value: any): any => {
		if(!value)
		{
			return [];
		}

		if(typeof(value) === 'string')
		{
			return [value];
		}

		return value;
	},
	sprintf: (format: string, args: any[]): string => {
		return vsprintf(format, args);
	},
	decrypt_bidirectional(text: string)
	{
		const config: any = {
			padding: CryptoJS.pad.Pkcs7,
			mode: CryptoJS.mode.ECB as any
		};
		const result = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_BIDIRECTIONAL_KEY || ''), config);
		return result.toString(CryptoJS.enc.Utf8);
	}
};

const Plugin = {
	install(_Vue: typeof Vue)
	{
		_Vue.prototype._ = CommonModule;
	}
};

Vue.use(Plugin);
