import company from '@/routes/company';
import contact from '@/routes/contact';
import home from '@/routes/home';
import laboratory from '@/routes/laboratory';
import service from '@/routes/service';
import kakaoTalk from '@/routes/kakaoTalk';

export default (
	[
		company,
		contact,
		home,
		laboratory,
		service,
		kakaoTalk
	]
	.reduce(
		(acc, val) => acc.concat(val),
		[]
	)
);
