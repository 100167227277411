import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
	/**
	 * @brief 시작화면
	 */
	{
		name: 'home',
		path: '/',
		meta: { layout: 'default' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/Home.vue')
	},

	/**
	 * @brief 호반on 링크
	 */
	{
		name: 'hoban',
		path: '/HobanON',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/HobanLink.vue')
	},

	/**
	 * @brief 서한 링크
	 */
	{
		name: 'seohan',
		path: '/seohan',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/SeoHan.vue')
	},

	/**
	 * @brief 남해종합건설 링크
	 */
	{
		name: 'namhae',
		path: '/namhae',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/namhae.vue')
	},

	/**
	 * @brief 화성산업 링크
	 */
	{
		name: 'HS',
		path: '/HS',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/sh.vue')
	},

	/**
	 * @brief 극동건설 링크
	 */
	{
		name: 'kukdong',
		path: '/kukdong',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/kukdong.vue')
	},

	/**
	 * @brief 서영건설플러스 링크
	 */
	{
		name: 'syplus',
		path: '/syplus',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/syplus.vue')
	},

	/**
	 * @brief 동원개발 링크
	 */
	{
		name: 'dongwon',
		path: '/dongwon',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/dongwon.vue')
	},

	/**
	 * @brief 제주특별자치도개발공사 링크
	 */
	{
		name: 'jpdc',
		path: '/jpdc',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "home" */ '@/views/hoban/jpdc.vue')
	},

	/**
	 * @brief 404 Not Found
	 */
	{
		name: 'not-found',
		path: '*',
		meta: { layout: 'empty' },
		component: () => import (/* webpackChunkName: "not-found" */ '@/views/NotFound.vue')
	}
];

export default routes;
